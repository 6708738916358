span.dashboard-calender {
    padding: 15px 5px;
}

.redemption-summary-item {
    margin-left: 10px;
    font-weight: 400 !important;
}
.dashboard-red-summery {
    float: right !important;
    font-size: 20px !important;
    font-weight: normal !important;
}