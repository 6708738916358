/* Styles for the custom horizontal scrollbar */
.custom-scrollbar::-webkit-scrollbar {
    margin-top: 5px;
    height: 20px; /* Set the height of the horizontal scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888; /* Handle color */
    border-radius: 2px; /* Rounded corners for the handle */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; /* Handle color on hover */
  }
  