.privacy{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: 'Calibri', sans-serif;
    ul li.mainContainer1    {
        list-style-type: circle;
        line-height: 1;
        text-wrap: wrap;
    }
}
.mainContainer1{
    width: 95%;
    float: "left";
}
@media screen and (max-width: 800px) {
    h3.mainContainer1{
        margin: 0;
    }
  }