.error-message {
    color: red;
    font-size: 13px;
    /* margin-bottom: 10px; */
    margin-top: -23px;
    position:absolute
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    display: block;
}
.form-group {
    margin-bottom: 22px;
}

.mt25 {
    margin-top: 25px;
    margin-bottom: 25px;
}
/*  otp input box */

.otp-modal .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background: #e5f7ff;
} 

.box-feild input {
    font-size: 16px;
    width: 40px !important;
    height: 40px !important;
    border-radius: 0px;
    margin-left: 10px;
    text-align: center !important;
    color: #000;
    font-weight: 600;
    padding-left: 0px;
    margin-bottom: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.error-message-modal {
    color: red;
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: -7px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 99999;
}
.dob-100{
    width: 100%;
}
.dob-100 .from-contro{
    width: 100% !important;
}
/* otp input box */
